import XLSX from 'xlsx';
import moment from 'moment';

/**
 * 匯出 xlsx 檔案
 * @param {string} type
 * @param {object} time
 * @param {object} data
 * 寫入xlsx type為 t(taday), d(daily) ,m(monthly)
 * time是搜尋時間
 * data是要匯出資料
 */
export function exportXlsx(type, time, data) {
	const filename = getFileName(type, time);
	const wb = XLSX.utils.book_new();
	const sheetNames = Object.keys(data);
	for (let i = 0; i < sheetNames.length; i += 1) {
		const first = data[sheetNames[i]];
		const second = clean(first);
		let ws = XLSX.utils.aoa_to_sheet(second);
		XLSX.utils.book_append_sheet(wb, ws, sheetNames[i]);
	}
	XLSX.writeFile(wb, filename);
}

// 根據類型不同產生檔名
function getFileName(type, date) {
	let fileNmae = '.xlsx';
	if(type === 't') {
		fileNmae = moment(date[0]).format('YYYYMMDD') + fileNmae;
	}
	if(type === 'd') {
		fileNmae = moment(date[0]).format('YYYYMMDD') + '-' + moment(date[1]).format('YYYYMMDD') + fileNmae;
	}
	if(type === 'm') {
		fileNmae = moment(date[0]).format('YYYYMM') + '-' + moment(date[1]).format('YYYYMM') + fileNmae;
	}
	return fileNmae;
}

// 物件重組
function clean(data) {
	const op = [];
	const keys = Object.keys(data[0]);
	op.push(keys);
	for (let i of data) {
		let tmp = [];
		for(let j = 0; j < keys.length; j += 1){
			tmp.push(i[keys[j]]);
		}
		op.push(tmp);
	}
	return op;
}

<template lang="pug">
	v-toolbar(flat='' color='#383F48' height='30px')
		v-dialog(v-model='dialog' max-width='400px' overlay-opacity='0.9')
			template(v-slot:activator='{ on }')
				v-btn.exportBtn.ml-auto( v-on='on'  :ripple="false" tile='' color='#383F48' :disabled='disabled')
					v-icon(left='') save_alt
					span {{$t('common.exportTable')}}
			v-card
				v-card-title {{$t('common.exportXlsxFile')}}
					span.headline 
				v-card-actions.pa-8
					v-spacer
					v-btn(color="grey darken-2"  @click='closeDialog') {{$t('common.cancel')}}
					v-btn.defult-btn-color( @click='exportData') {{$t('common.confirm')}}
</template>
<script>
export default {
	props: ['exportResult'],
	data() {
		return {
			dialog: false,
			disabled : true
		};
	},
	methods: {
		closeDialog() {
			this.dialog = false;
		},
		exportData() {
			this.dialog = false;
			this.$emit('emitexportData');
		}
	},
	watch: {
		exportResult: function(val) {
			if (Object.keys(val).length !== 0) {
				this.disabled = false;
			}else{
				this.disabled = true;
			}
		},
	}
};
</script>

import _ from 'lodash';

// 過濾 null資料
export default async function repla(res){
	for(const i in res){
		for(const j of res[i]){
			for(const k in j){
				j[k] = _.isNull(j[k]) ? 0: j[k];
			}
		}
	}
	return res;
}

export function replaObjInArray(res){
	for(const i of res){
		for(const j in i){
			i[j] = _.isNull(i[j]) ? 0: i[j];
		}
	}
	return res;
}

export function replaObj(res){
	for(const i in res){
		res[i] = _.isNull(res[i]) ? 0: res[i];
	}
	return res;
}
